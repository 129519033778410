
import { defineComponent, ref, onMounted } from "vue";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { useRouter } from "vue-router";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "admin-add-new-user-component",
  components: { GoBackButton },
  props: {
    redirect: String,
    headerTitle: String,
  },
  data() {
    return {
      policies: [
        {
          publicId: "",
          businessTypeShortCode: "",
          startDate: "",
          expiryDate: "",
          status: "",
          sumInsured: 0,
          year: "",
          type: "",
          number: "",
          reference: "",
          premium: 0,
        },
      ],
      creditNotes: [
        {
          serialNumber: "",
          policyNumber: "",
          ibnPolicyNumber: "",
          businessType: "",
          amount: 0,
          status: "",
          transactionDate: "",
          policyPublicId: "",
          publicId: "",
        },
      ],
      invoices: [
        {
          invoiceNumber: "",
          ibnReference: "",
          endorsementType: "",
          premium: 0,
          narration: "",
          dateCreated: "",
          publicId: "",
        },
      ],
      payments: [
        {
          policyReference: "",
          serialNumber: "",
          invoiceNumber: "",
          premium: 0,
          paymentChannel: "",
          reference: "",
          dateCreated: "",
          metadata: { data: { status: "" } },
          policyPublicId: "",
          publicId: "",
        },
      ],
      claims: [
        {
          claimNumber: "",
          policyType: "",
          entity: "",
          lossType: "",
          status: "",
          amount: "",
          settledAmount: "",
          outstandingAmount: "",
          lossDate: "",
          reportedDate: "",
          publicId: "",
          hasClaimNumber: false,
          isSettlementOfferAccepted: false,
          settlementOffer: {},
          isDischargeVoucherApproved: false,
        },
      ],
      passwordVisible: false,
      displayShowPasswword: false,
      departments: [],
      roles: [],
      department: "",
      avatar: "",
      emailAddress: "",
      firstName: "",
      gender: "",
      lastName: "",
      maritalStatus: "",
      middleName: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      role: "",
    };
  },
  setup(props) {
    const {
      reinitialization,
      useReusableNavigation,
      capitalize,
      displayDatatable,
    } = ResusableFunctions();
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();
    const tabIndex = ref(0);

    const newMemberDetailsValidator = Yup.object().shape({
      firstName: Yup.string()
        .required()
        .label("First name"),
      middleName: Yup.string()
        .notRequired()
        .label("First name"),
      lastName: Yup.string()
        .required()
        .label("Last name"),
      emailAddress: Yup.string()
        .required()
        .label("Email address"),
      phoneNumber: Yup.number()
        .required()
        .label("Phone number"),
      gender: Yup.mixed()
        .required()
        .label("Gender"),
      maritalStatus: Yup.mixed()
        .required()
        .label("Martial status"),
      password: Yup.string()
        .min(8)
        .required()
        .label("Password"),
      confirmPassword: Yup.string()
        .min(8)
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label("Confirm Password"),
    });

    const createMember = (values, { resetForm }) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(variables.SUPERADMIN_USER_ROUTE, values)
              .then((response) => {
                variables.toastAlert(
                  "User was created successfully.",
                  "success"
                );
                resetForm();
                router.push({
                  name: props.redirect,
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    /**
     * Set active tab when the tab get clicked
     * @param event
     */
    const setActiveTab = (event) => {
      tabIndex.value = parseInt(event.target.getAttribute("data-tab-index"));
      // keep active tab
      localStorage.setItem("builderTab", tabIndex.value.toString());
    };

    onMounted(() => {
      displayDatatable([
        "basicExample",
        "basicExample1",
        "basicExample2",
        "basicExample3",
        "basicExample4",
      ]);
    });

    return {
      setActiveTab,
      tabIndex,
      submitButton1,
      createMember,
      newMemberDetailsValidator,
      useReusableNavigation,
      reinitialization,
      capitalize,
    };
  },
  created() {
    this.getDepartments();
    this.getRoles();
  },
  methods: {
    getDepartments() {
      //Fetch list of all departments
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.SUPERADMIN_DEPARTMENTS_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.departments = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getRoles() {
      //Fetch list of all departments
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.SUPERADMIN_ROLES_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.roles = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    removeImage() {
      this.avatar = "../media/avatars/blank.png";
    },
    acceptNumberOnly(event) {
      variables.acceptNumberOnly(event);
    },
    handleImageUpload(event) {
      const selectedImage = event.target.files[0]; //get the first file

      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);

      reader.onload = (e) => {
        this.avatar = reader.result as string;
      };

      reader.onerror = function(error) {
        variables.toastAlert(error, "error");
      };
    },
    generatePassword() {
      const passwordField = document.getElementById(
        "password"
      ) as HTMLInputElement;
      const confirmPasswordField = document.getElementById(
        "confirm-password"
      ) as HTMLInputElement;

      const generatedPassword = variables.generatePassword();
      passwordField.value = generatedPassword;
      confirmPasswordField.value = generatedPassword;
    },
    togglePasswordVisibility() {
      this.displayShowPasswword = this.password ? true : false;
      this.passwordVisible = !this.passwordVisible;
    },
    removeUnderscore(string) {
      return variables.removeUnderscore(string);
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
  },
  // watch: {
  //   // Watch for changes in the prop and update the local copy
  //   userRole(newValue) {
  //     this.role = newValue;
  //   },
  // },
});
