import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StaffAndAdminUserDetails = _resolveComponent("StaffAndAdminUserDetails")!

  return (_openBlock(), _createBlock(_component_StaffAndAdminUserDetails, {
    redirect: `admin-staff-users`,
    "header-title": `Create IBN Staff`,
    route: _ctx.route
  }, null, 8, ["route"]))
}