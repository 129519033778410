
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import StaffAndAdminUserDetails from "@/components/admin/StaffAndAdminUserDetails.vue";
import variables from "@/router/api";

export default defineComponent({
  name: "admin-ibn-staff-details",
  components: {
    StaffAndAdminUserDetails,
  },
  data() {
    return {
      route: variables.SUPERADMIN_COOPERATIVE_ROUTE,
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Details", ["Users", "Administrators"]);
    });
  },
  created() {
    //Set page title
    document.title = "Administrator Details | " + process.env.VUE_APP_TITLE;
  },
});
